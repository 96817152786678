<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">About Regulatory Oglibations</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container class="my-5">
          <div>Beta version of the global IMPACT initiatives. Contact Lucas Ocelewicz for more information.</div>
          <div>See <a href="https://home.kpmg/xx/en/home/insights/2020/06/kpmg-impact.html" target="_blank">KPMG Global IMPACT</a> website for more general information.</div>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$stat.log({ page: 'about', action: 'open about' })
  }
}
</script>
